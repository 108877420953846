<template>
  <div>
    <PageHeaderDisplay
      title="Imports"
      sub-title="View completed and in-progress imports and start new imports"
      category="Import Management"
      category-logo="fa-file-import"
      :category-route="{ name: 'import-list' }"
    />
    <div class="content">
      <div class="mb-5">
        <SectionTitle icon="fa-plus">New</SectionTitle>

        <p class="mb-2">Select the type of import you would like to perform...</p>
        <div class="d-flex justify-content-center align-items-center">
          <router-link class="btn btn-primary mr-2" :to="{ name: 'import-upload', query: { type: 'asset' } }"
            ><i class="fa fa-buildings mr-2"></i>Assets</router-link
          >
          <router-link class="btn btn-primary mr-2" :to="{ name: 'import-upload', query: { type: 'account' } }"
            ><i class="fa fa-meter-bolt mr-2"></i>Accounts</router-link
          >
          <router-link class="btn btn-primary mr-2" :to="{ name: 'import-upload', query: { type: 'entity' } }"
            ><i class="fa fa-sitemap mr-2"></i> Entities</router-link
          >
          <router-link class="btn btn-primary mr-2" :to="{ name: 'import-upload', query: { type: 'invoice' } }"
            ><i class="fa fa-file-invoice mr-2"></i>Invoices</router-link
          >
          <router-link class="btn btn-primary mr-2" :to="{ name: 'import-upload', query: { type: 'contract' } }"
            ><i class="fa fa-file-contract mr-2"></i>Contracts</router-link
          >
          <router-link class="btn btn-primary mr-2" :to="{ name: 'import-upload', query: { type: 'reading' } }"
            ><i class="fa fa-input-numeric mr-2"></i> Readings</router-link
          >
          <router-link class="btn btn-primary mr-2" :to="{ name: 'import-upload', query: { type: 'consumption' } }"
            ><i class="fa fa-input-numeric mr-2"></i> Consumption (Non-Cumulative)</router-link
          >
        </div>
      </div>

      <div>
        <SectionTitle icon="fa-clock-rotate-left">History</SectionTitle>
        <div v-if="loading">
          <Spinner />
        </div>
        <div v-else>
          <p>
            Below is a list of all imports that have been processed. Click the <strong>Details</strong> button to see more details about an import,
            and to also rollback an import.
          </p>
          <table class="table">
            <thead class="thead-dark">
              <th>Date</th>
              <th>Type</th>
              <th>Status</th>
              <th>Notes</th>
              <th>Imported By</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="item in sortedImports" :key="item._id">
                <td class="font-w600">
                  <router-link :to="{ name: 'import-detail', params: { id: item._id } }">{{
                    item.createdAt | date('DD/MM/YYYY HH:mm:ss')
                  }}</router-link>
                </td>
                <td class="text-capitalize">{{ item.type }}</td>
                <td class="text-capitalize">{{ item.status }}</td>
                <td>{{ item.notes }}</td>
                <td>{{ item.userSub | user(users) }}</td>

                <td><router-link class="btn btn-primary btn-sm" :to="{ name: 'import-detail', params: { id: item._id } }">Details</router-link></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import SectionTitle from '@/components/base/SectionTitle';
import Spinner from '@/components/Spinner';

export default {
  name: 'ImportList',
  components: {
    PageHeaderDisplay,
    SectionTitle,
    Spinner
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      users: 'company/users',
      imports: 'import/imports',
      setup: 'import/setup',
      errorAction: 'import/errorAction',
      loadingAction: 'import/loadingAction'
    }),
    sortedImports() {
      const imports = [...this.imports].filter(item => ['rolledback', 'imported', 'partial-import'].includes(item.status));

      return imports.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
  },
  async mounted() {
    this.resetImport();

    if (this.users.length === 0) {
      await this.listUsers({ id: this.$auth.companyId });
    }

    await this.listImports();

    this.loading = false;
  },
  methods: {
    ...mapActions({
      listImports: 'import/list',
      listUsers: 'company/listUsers'
    }),
    ...mapMutations({
      resetImport: 'import/RESET'
    })
  }
};
</script>
